<template>
  <div class="reserve-page">
    <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="form" v-loading="loading">
      <div class="form-box">
        <el-form-item label="宴会大厅" prop="hallBizId">
          <el-select style="width: 150px" v-model="form.hallBizId" placeholder="宴会厅" @change="onBallroomChange">
            <el-option v-for="item in ballroomList" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="宴会类型" prop="categorySelectedBizId">
          <el-select style="width: 150px" v-model="form.categorySelectedBizId" @change="onBallroomTypeChange" placeholder="类型">
            <el-option v-for="item in categories" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="宴会日期" prop="startDate">
          <el-date-picker style="width: 150px" :picker-options="pickerOptions" v-model="form.startDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="20px" prop="startTimeQuantumSelectedBizId">
          <el-radio-group v-model="form.startTimeQuantumSelectedBizId">
            <el-radio v-for="item in startTimeQuantums" :key="item.bizId" :label="item.bizId">{{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="form-box">
        <el-form-item label="客户姓名" prop="customerName">
          <el-input style="width: 150px" v-model="form.customerName"></el-input>
        </el-form-item>
        <el-form-item label-width="20px" prop="genderSelectedBizId">
          <el-radio-group v-model="form.genderSelectedBizId">
            <el-radio v-for="item in genders" :key="item.bizId" :label="item.bizId">{{ item.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="联系方式" prop="customerPhoneNumber">
          <el-input style="width: 150px" v-model="form.customerPhoneNumber"></el-input>
        </el-form-item>
      </div>
      <div class="form-box" v-if="bindAttributes">
        <div style="display: flex" v-for="(item, index) in bindAttributes" :key="index">
          <el-form-item :label="item.bindAttributeName1">
            <el-input style="width: 150px" v-model="item.bindAttributeValue1"></el-input>
          </el-form-item>
          <el-form-item :label="item.bindAttributeName2">
            <el-input style="width: 150px" v-model="item.bindAttributeValue2"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="form-box form-box-margintop">
        <el-form-item label="预定桌数" prop="reserveTableNumber">
          <el-input style="width: 150px" v-model="form.reserveTableNumber"></el-input>
        </el-form-item>
        <el-form-item label="浮动桌数">
          <el-input style="width: 150px" v-model="form.backupTableNumber"></el-input>
        </el-form-item>
        <el-form-item v-if="form.categorySelectedBizId" label="预定餐标">
          <!--filterable-->
          <el-select allow-create filterable default-first-option style="width: 150px" v-model="form.mealStandardSelectedBizId" placeholder="餐标" @change="mealStandardChange">
            <el-option v-for="item in mealStandards" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户经理" prop="customerManagerSelectedBizId">
          <el-select style="width: 150px" v-model="form.customerManagerSelectedBizId" placeholder="客户经理">
            <el-option v-for="item in customerManagers" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="form-box">
        <el-form-item label="收费服务">
          <el-button @click="appreciationServeVisible = true" type="text">添加</el-button>
        </el-form-item>
        <!--        <el-form-item label="关联雅间">-->
        <!--          <el-button @click="elegantRoomVisible = true" type="text">关联</el-button>-->
        <!--        </el-form-item>-->
      </div>
      <div class="form-box form-box-none form-box-margintop" v-if="payMode.length > 0">
        <div class="payway" v-for="(i, index) in form.paymentJson" :key="index">
          <el-form-item label-width="10px" prop="region">
            <el-radio-group style="width: 150px" @change="onPayChange" v-model="i.verifyMode">
              <el-radio v-for="(item, index) in payments" :key="item.bizId" :label="index + 1">{{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="payway-item">
            <template v-if="i.verifyMode == 1">
              <el-form-item label="定金金额" prop="region">
                <el-input type="number" style="width: 100px" v-model="i.amount"></el-input>元
              </el-form-item>
              <el-form-item label="支付方式" prop="region">
                <el-select style="width: 100px" v-model="i.payModeSelectedBizId" placeholder="支付方式">
                  <el-option v-for="item in payMode" :key="item.value" :label="item.label" :value="item.bizId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="支付项目" prop="region">
                <el-select style="width: 100px" v-model="i.payCategorySelectedBizId" placeholder="服务类型">
                  <el-option v-for="item in payCategories" :key="item.value" :label="item.label" :value="item.bizId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="收据编号" prop="region">
                <el-input v-model="i.receiptCode" placeholder="收据编号"></el-input>
              </el-form-item>
              <el-form-item v-show="!i.bizId" label-width="20px">
                <i @click="removePaymentJson(index)" class="el-icon-circle-close"></i>
              </el-form-item>
            </template>
            <template v-if="i.verifyMode == 2">
              <el-form-item label="担保金额" prop="region">
                <el-input style="width: 100px" v-model="i.amount"></el-input>
              </el-form-item>
              <el-form-item label="担保类型" prop="region">
                <el-select style="width: 100px" v-model="i.guarantorCategorySelectedBizId" placeholder="担保类型">
                  <el-option v-for="item in guarantorCategory" :key="item.value" :label="item.label" :value="item.bizId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="担保人" prop="region">
                <el-select style="width: 100px" v-model="i.customerGuarantorSelectedBizId" placeholder="担保人">
                  <el-option v-for="item in customerGuarantor" :key="item.value" :label="item.label" :value="item.bizId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-show="!i.bizId" label-width="20px">
                <i @click="removePaymentJson(index)" class="el-icon-circle-close"></i>
              </el-form-item>
            </template>
          </div>
        </div>
        <el-button @click="onAddconfirm" class="addpayway" type="text" size="mini">+</el-button>
      </div>
      <div class="form-box form-box-margintop">
        <el-form-item label="客户来源">
          <el-radio-group style="width: 80vw" v-model="form.customerSourceSelectedBizId">
            <el-radio style="margin: 5px 10px" v-for="item in customerSources" :key="item.bizId" :label="item.bizId">{{ item.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="form-box form-box-margintop" v-if="refeType">
        <el-form-item label="关联宴会厅">
          <el-select multiple style="min-width: 300px" v-model="form.referHallBizIds" placeholder="宴会厅">
            <el-option v-for="item in referBallRoomList" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="关联雅间">
          <el-select multiple style="min-width: 300px" v-model="form.balls" placeholder="雅间名称">
            <el-option v-for="item in ballroomList" :key="item.value" :label="item.label" :value="item">
            </el-option>
          </el-select>
        </el-form-item> -->
      </div>
      <div class="form-box form-box-margintop">
        <el-form-item label="详情备注">
          <el-input type="textarea" style="width: 800px" :rows="2" v-model="form.description"></el-input>
        </el-form-item>
      </div>
      <div class="form-box form-box-margintop">
        <el-form-item label="上传合同">
          <el-upload :multiple="true" name="files" :data="uploadData" :file-list="fileList" :headers="uploadHeaders" :action="action + 'banquet/reserve/attachment/upload'" list-type="picture-card" :on-success="handleSuccess" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
            <i style="font-size: 20px" class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
      </div>
      <br />
      <div class="form-box-margintop" style="width: 100%; margin-bottom: 20px">
        <el-form-item style="text-align: center">
          <el-button type="primary" @click="submitForm('form', 0)">预定</el-button>
          <el-button type="primary" @click="submitForm('form', 1)">预定并短信</el-button>
          <el-button type="primary" @click="resetForm">重置</el-button>
        </el-form-item>
      </div>
      <br /><br />
    </el-form>
    <!--弹窗选择增值服务-->
    <el-dialog :close-on-click-modal="false" title="添加增值服务" :visible.sync="appreciationServeVisible">
      <el-table :data="providerServices" :summary-method="getSummaries" show-summary style="width: 100%">
        <el-table-column prop="name" label="服务类型" align="center" width="180">
        </el-table-column>
        <el-table-column prop="price" label="单价" align="center" width="180">
          <template slot-scope="scope">
            <el-input v-model="scope.row.price" placeholder="请输入单价">
            </el-input>
          </template>
        </el-table-column>
        <el-table-column align="center" label="数量">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.num" size="mini" :min="0" :max="10">
            </el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="amount" align="center" label="总价">
          <template slot-scope="scope">
            {{ (scope.row.amount = scope.row.price * scope.row.num) }}
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="appreciationServeVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAppreciationServeClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 弹窗选择关联雅间-->
    <el-dialog :close-on-click-modal="false" title="关联雅间" :visible.sync="elegantRoomVisible">
      <p>关联雅间</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="elegantRoomVisible = false">取 消</el-button>
        <el-button type="primary" @click="onElegantRoomClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import { baseUrl, imageUrl } from '../../utils/api'

import datas from './json/reserver.json'
import parmentsload from './json/paymentsload.json'
import referlists from './json/referlist.json'
let records = {} // 酒店信息
let token = ''
let oldForm = null
if (window.localStorage.getItem('token')) {
  token = window.localStorage.getItem('token').toString()
}
export default {
  name: 'Reserve',
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000
        }
      },
      action: baseUrl,
      dialogVisible: false,
      dialogImageUrl: '',
      uploadHeaders: {
        'Access-Control-Allow-Origin': '*',
        accessToken: 'Bearer ' + token
      },
      uploadData: {
        num: 1
      },
      fileList: [],
      form: {
        // 表单提交数据
        bizId: null,
        backupTableNumber: null, // 预备桌数
        hotelBizId: null, // 酒店的bizId
        categorySelectedBizId: '', // 选择的宴会类型的bizId
        dateFormat: '1', // 时间格式，时间还是时间段  1:时间  2: 时间段
        paymentJson: [
          {
            id: uuid(),
            verifyMode: null
          }
        ], // 确认方式
        description: '', // 备注
        genderSelectedBizId: null, // 选择的性别的bizId
        mealStandardSelectedBizId: null, // 选择的餐标的bizId
        customerSourceSelectedBizId: null, // 选择的客户来源的bizId
        hallBizId: null, // 宴会厅的bizId
        providerServiceJson: [], // 增值服务
        customerName: null, // 客户姓名
        reserveTableNumber: null, // 预定桌数
        customerManagerSelectedBizId: null, // 选择的客户经理的bizId
        bindAttributes: '', // 宴会类型所绑定的属性
        startTimeQuantumSelectedBizId: null, // 选择的是上午还是晚上的 bizId
        customerPhoneNumber: null, // 客户电话
        startDate: null, // 预订日期的开始时间
        referHallBizIds: []
      },
      rules: {
        // 验证规则
        hallBizId: [
          {
            required: true,
            message: '请选择宴会厅',
            trigger: 'blur'
          }
        ],
        categorySelectedBizId: [
          {
            required: true,
            message: '请选择宴会类型',
            trigger: 'change'
          }
        ],
        startDate: [
          {
            required: true,
            message: '请选择宴会日期',
            trigger: 'change'
          }
        ],
        startTimeQuantumSelectedBizId: [
          {
            required: true,
            message: '请选择宴会时间',
            trigger: 'change'
          }
        ],
        customerName: [
          {
            required: true,
            message: '请填写客户姓名',
            trigger: 'blur'
          }
        ],
        genderSelectedBizId: [
          {
            required: true,
            message: '请选择客户性别',
            trigger: 'blur'
          }
        ],
        customerPhoneNumber: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur'
          },
          {
            min: 11,
            max: 11,
            message: '长度11个字符'
          }
        ],
        reserveTableNumber: [
          {
            required: true,
            message: '请填写预定桌数',
            trigger: 'blur'
          }
        ],
        mealStandardSelectedBizId: [
          {
            required: true,
            message: '请选择餐标',
            trigger: 'change'
          }
        ],
        customerManagerSelectedBizId: [
          {
            required: true,
            message: '请选择客户经理',
            trigger: 'blur'
          }
        ]
      },
      appreciationServeVisible: false, // 增值服务弹窗是否可见
      elegantRoomVisible: false, // 关联雅间弹窗是否可见
      customerManagers: [], // 客户经理
      customerSources: [], // 客户来源
      providerServices: [], // 增值服务
      genders: [], // 客户性别
      categories: [], // 婚宴类型
      bindAttributes: [], // 婚宴类型人员数据
      mealStandards: [], // 餐标
      startTimeQuantums: [], // 宴会日期或会议日期开始时间
      endTimeQuantums: [], // 会议日期结束时间
      paymentJson: [], // 确认方式列表
      payments: [], // 确认方式
      payMode: [], // 支付方式
      payCategories: [], // 支付类型
      guarantorCategory: [], // 担保类型
      customerGuarantor: [], // 担保人
      referBallRoomList: [],
      refeData: [],
      status: 1,
      loading: false,
      halls: [],
      refeType: false
    }
  },
  computed: {
    ballroomList() {
      return this.$store.state.ballroomList
    }
  },
  methods: {
    // 餐标改变事件
    async mealStandardChange(e) {
      for (let i = 0; i < this.mealStandards.length; i++) {
        if (this.mealStandards[i].bizId === e) return false
      }
      const data = {
        hotelBizId: this.form.hotelBizId,
        categoryBizId: this.form.categorySelectedBizId,
        bizId: this.form.hallBizId,
        price: e
      }
      const res = await this.postRequest(
        'admin/hall/mealStandard/add',
        this.qs.stringify(data)
      )
      if (res.status !== 200) {
        this.$message.error(res.message)
        return false
      }
      //   console.log('res', res)
    },
    // 删除确认方式
    removePaymentJson(index) {
      if (index === 0) {
        this.form.paymentJson[index].verifyMode = null
      } else {
        this.form.paymentJson.splice(index, 1)
      }
    },
    async paymentsF() {
      const payments = parmentsload.records

      if (
        payments[0].deposits &&
        payments[0].deposits.length > 0 &&
        payments[0].guarantors &&
        payments[0].guarantors.length > 0
      ) {
        const paymentarray = [
          payments[0].deposits[0],
          payments[0].guarantors[0]
        ]
        if (paymentarray && paymentarray.length > 0) {
          this.payments = paymentarray.map(o => {
            return {
              value: o.code,
              label: o.name,
              bizId: o.bizId
            }
          })
          // console.log(this.payments)
          // 支付方式
          if (paymentarray[0].payModes && paymentarray[0].payModes.length > 0) {
            this.payMode = paymentarray[0].payModes.map(o => {
              return {
                value: o.code,
                label: o.name,
                bizId: o.bizId
              }
            })
          }
          // 支付类型
          const payCategoriess = paymentarray[0].payCategories || []
          this.payCategories = payCategoriess.map(o => {
            return {
              value: o.code,
              label: o.name,
              bizId: o.bizId
            }
          })
          // 担保类型
          const guarantorCategorys = paymentarray[1].payCategories || []
          if (guarantorCategorys) {
            this.guarantorCategory = guarantorCategorys.map(o => {
              return {
                value: o.code,
                label: o.name,
                bizId: o.bizId
              }
            })
          }
          // 担保人
          const customerGuarantors = paymentarray[1].customerGuarantors || []
          if (customerGuarantors) {
            this.customerGuarantor = customerGuarantors.map(o => {
              return {
                value: o.code,
                label: o.name,
                bizId: o.bizId
              }
            })
          }
        }
      }
    },
    // 增值服务确认事件
    onAppreciationServeClick() {
      this.appreciationServeVisible = false
      const providerServices = this.providerServices.map(o => {
        return {
          price: o.price,
          num: o.num,
          bizId: o.bizId
        }
      })
      this.form.providerServiceJson = providerServices.filter(
        val => val.num > 0
      )
    },
    // 关联雅间确认事件
    onElegantRoomClick() {
      this.elegantRoomVisible = true
    },
    // 确认提交
    submitForm(form, v) {
      const index = this.form.paymentJson.length - 1
      const paymentJson = this.form.paymentJson[index]
      switch (paymentJson.verifyMode) {
        case 1:
          if (
            !paymentJson.amount ||
            !paymentJson.payCategorySelectedBizId ||
            !paymentJson.payModeSelectedBizId
          ) {
            this.$message('请完善预收款信息')
            return false
          }
          break
        case 2:
          if (
            !paymentJson.amount ||
            !paymentJson.customerGuarantorSelectedBizId ||
            !paymentJson.guarantorCategorySelectedBizId
          ) {
            this.$message('请完善担保人信息')
            return false
          }
          break
      }
      this.$refs[form].validate(async valid => {
        if (valid) {
          this.loading = true
          // 数据提交
          const data = {
            ...this.form,
            sendMessage: v
          }
          if (this.bindAttributes && this.bindAttributes.length > 0) {
            this.bindAttributes.forEach(i => {
              data.bindAttributes += `${i.bindAttributeValue1},${i.bindAttributeValue2};`
            })
          }
          if (data.attachmentJson) {
            data.attachmentJson = JSON.stringify(data.attachmentJson)
          }
          data.providerServiceJson = JSON.stringify(data.providerServiceJson)
          data.paymentJson = JSON.stringify(data.paymentJson)
          let type = 'add'
          if (this.form.bizId) {
            type = 'update'
            data.retainBizId =
              this.$store.state.banquetReservation.retainBizId || ''
          }
          if (data.referHallBizIds && data.referHallBizIds.length > 0) {
            data.referHallBizIds = data.referHallBizIds.join(';')
          }
          // const param1 = {
          //   bizId: this.form.bizId
          // }
          // const resource = await this.getRequest(
          //   `banquet/reserve/checkProcess`,
          //   param1
          // )
          // console.log(JSON.stringify(resource))
          // if (resource.status !== 200 && this.status === 1) {
          //   this.loading = false
          //   this.$message.error(resource.message)
          //   return false
          // }
          data.status = this.status
          const res = await this.postRequest(
            `banquet/reserve/${type}/process`,
            this.qs.stringify(data)
          )
          // if (type === 'update' && this.status === 1) {
          //   // 判断是否需要关联宴会厅
          //   if (this.refeData != null && this.refeData.length > 0) {
          //     // 开始页面循环请求
          //     for (let index = 0; index < this.refeData.length; index++) {
          //       data.hallBizId = this.refeData[index].hallBizId
          //       data.hallName = this.refeData[index].hallName
          //       data.hall = this.refeData[index].hall
          //       data.bizId = this.refeData[index].bizId
          //       data.retainBizId = this.refeData[index].retainBizId
          //       console.log('开始发送第二次');
          //       await this.postRequest(
          //         `banquet/reserve/${type}/process`,
          //         this.qs.stringify(data)
          //       )
          //     }
          //   }
          // }
          this.loading = false
          if (res.status !== 200) {
            this.$message.error(res.message)
            return false
          }
          this.$message({
            duration: 10000,
            message: '预定成功,自动跳转到销控表',
            type: 'success'
          })
          setTimeout(e => {
            const submenu = {
              path: '/banquet/salescontrol-table',
              name: '宴会预定',
              bizId: '/banquet/salescontrol-table'
            }
            // 加入keepalive缓存
            this.$store.commit('addKeepAliveCache', '宴会销控表')
            // 修改选中菜单
            this.$store.commit('selectMenu', submenu)
            this.$router.push('/banquet/salescontrol-table')
          }, 300)
        } else {
          this.$message('信息填写完整后，可预定!')
          return false
        }
      })
    },

    // submitForm(form, v) {
    //   const data = {
    //     ...this.form,
    //     sendMessage: v
    //   }
    //   if (data.referHallBizIds && data.referHallBizIds.length > 0) {
    //     data.referHallBizIds = data.referHallBizIds.join(';')
    //   }
    //   console.log(JSON.stringify(data));
    // },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
    },
    // 文件上传成功
    handleSuccess(res, file, fileList) {
      if (res.status !== 200) {
        return false
      }
      const { records } = res.pageModel
      const data = {
        bizId: records[0].bizId,
        suffix: records[0].suffix,
        name: records[0].originalFileName,
        previewUrl: records[0].previewUrl
      }
      if (!this.form.attachmentJson.includes(data)) {
        this.form.attachmentJson.push(data)
      }
    },
    handleRemove(file, fileList) {
      const attachments = []
      for (let i = 0; i < this.form.attachmentJson.length; i++) {
        for (let j = 0; j < fileList.length; j++) {
          if (
            this.form.attachmentJson[i].name === fileList[j].name &&
            imageUrl + this.form.attachmentJson[i].previewUrl ===
              fileList[j].url
          ) {
            attachments.push(this.form.attachmentJson[i])
            break
          }
        }
      }
      this.form.attachmentJson = attachments
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 房间号的合计去掉
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计'
        } else if (index === 3) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          } else {
            sums[index] = 'N/A'
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 获取选择该宴会厅下的初始化数据
    async getBallroomData(e) {
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
      if (this.$store.state.hotellist.length > 0) {
        this.form.hotelBizId = this.$store.state.hotellist[0].bizId
      }
      const {
        hallBizId,
        bizId,
        startDate,
        startTimeQuantumSelectedBizId,
        retainBizId
      } = this.$store.state.banquetReservation
      if (!e) {
        hallBizId
          ? (this.form.hallBizId = hallBizId)
          : (this.form.hallBizId = '')
      } else {
        this.form.hallBizId = e
      }
      bizId ? (this.form.bizId = bizId) : (this.form.bizId = '')
      startDate
        ? (this.form.startDate = startDate)
        : (this.form.startDate = (oldForm && oldForm.startDate) || '')
      startTimeQuantumSelectedBizId
        ? (this.form.startTimeQuantumSelectedBizId = startTimeQuantumSelectedBizId)
        : (this.form.startTimeQuantumSelectedBizId = '')
      const data = {
        reservedCategory: 'banquet',
        hotelBizId: this.form.hotelBizId,
        hallBizId: this.form.hallBizId,
        bizId: this.form.bizId,
        retainBizId
      }

      records = datas.records[0]

      this.status = records.status

      if (records.referBanquets != null && records.referBanquets.length > 0) {
        // 当前房间有关联的宴会厅 -> 请求接口
        const param = {
          bizId: records.bizId
        }

        const source = await this.getRequest('banquet/reserve/loadRefe', param)
        if (source.body && source.body != null) {
          this.refeData = source.body
        }
      }
      if (records === undefined) {
        return false
      }
      const {
        customerManagers,
        customerSources,
        providerServices,
        genders,
        hall,
        payments
      } = records
      // 客户经理
      if (customerManagers && customerManagers.length > 0) {
        this.customerManagers = customerManagers.map(o => {
          return {
            value: o.code,
            label: o.name,
            bizId: o.bizId
          }
        })
      } else {
        await this.getCustomerManager()
      }
      // 客户来源
      this.customerSources = customerSources || []
      // 增值服务
      if (providerServices && providerServices.length > 0) {
        this.form.providerServiceJson = []
        this.providerServices = providerServices.map(o => {
          if (o.selected === 1) {
            const data = {
              price: o.price,
              num: o.num,
              bizId: o.bizId
            }
            this.form.providerServiceJson.push(data)
          }
          return {
            price: o.price,
            num: o.num,
            name: o.name,
            bizId: o.bizId,
            amount: o.amount
          }
        })
      }
      // 客户性别
      this.genders = genders || []
      if (hall && hall.categories && hall.categories.length > 0) {
        // 酒店类型
        hall.categories = hall.categories.filter(o => {
          return o.reserveDate.dateFormat === 1
        })
        this.categories = hall.categories.map(o => {
          return {
            value: o.code,
            label: o.name,
            bizId: o.bizId
          }
        })
      }
      // 确认方式
      if (payments !== null && payments.length > 0) {
        // console.log('payments', payments)
        this.form.paymentJson = []
        payments.forEach(o => {
          switch (o.verifyMode) {
            case 1:
              if (o.deposits && o.deposits.length > 0) {
                const deposits = o.deposits[0]
                // 全部有值
                const confirmObject = {
                  id: uuid(),
                  verifyMode: 1,
                  payCategorySelectedBizId: '',
                  payModeSelectedBizId: '',
                  bizId: deposits.bizId,
                  receiptCode: deposits.receiptCode || '',
                  amount: deposits.amount || ''
                }
                for (let j = 0; j < deposits.payCategories.length; j++) {
                  if (deposits.payCategories[j].selected === 1) {
                    confirmObject.payCategorySelectedBizId =
                      deposits.payCategories[j].bizId
                    break
                  }
                }
                for (let j = 0; j < deposits.payModes.length; j++) {
                  if (deposits.payModes[j].selected === 1) {
                    confirmObject.payModeSelectedBizId =
                      deposits.payModes[j].bizId
                    break
                  }
                }
                this.form.paymentJson.push(confirmObject)
              }
              break
            case 2:
              if (o.guarantors && o.guarantors.length > 0) {
                const guarantors = o.guarantors[0]
                // 全部有值
                const confirmObject = {
                  id: uuid(),
                  verifyMode: 2,
                  bizId: guarantors.bizId,
                  customerGuarantorSelectedBizId: '',
                  guarantorCategorySelectedBizId: '',
                  amount: guarantors.amount || ''
                }
                for (let j = 0; j < guarantors.customerGuarantors.length; j++) {
                  if (guarantors.customerGuarantors[j].selected === 1) {
                    confirmObject.customerGuarantorSelectedBizId =
                      guarantors.customerGuarantors[j].bizId
                    break
                  }
                }
                for (let j = 0; j < guarantors.payCategories.length; j++) {
                  if (guarantors.payCategories[j].selected === 1) {
                    confirmObject.guarantorCategorySelectedBizId =
                      guarantors.payCategories[j].bizId
                    break
                  }
                }
                this.form.paymentJson.push(confirmObject)
              }
              break
          }
        })
      }

      await this.paymentsF()
      // 赋值
      this.form.bizId = records.bizId
      this.form.customerName =
        records.customerName || (oldForm && oldForm.customerName)
      this.form.customerPhoneNumber =
        records.customerPhoneNumber || (oldForm && oldForm.customerPhoneNumber)
      this.form.reserveTableNumber =
        records.reserveTableNumber || (oldForm && oldForm.reserveTableNumber)
      this.form.backupTableNumber =
        records.backupTableNumber || (oldForm && oldForm.backupTableNumber) || 0
      this.form.description =
        records.description || (oldForm && oldForm.description) || ''
      this.form.attachmentJson = records.attachments || []
      if (this.form.attachmentJson) {
        this.fileList = this.form.attachmentJson.map(o => {
          return {
            name: o.name,
            url: imageUrl + o.previewUrl
          }
        })
      }

      this.form.referHallBizIds = records.referHallBizIds
      if (records.genders && records.genders.length > 0) {
        let _genders = records.genders.filter(i => {
          return i.selected === 1
        })
        _genders.length > 0
          ? (_genders = _genders[0].bizId)
          : (_genders = (oldForm && oldForm.genderSelectedBizId) || '')
        this.form.genderSelectedBizId = _genders
      }
      if (records.customerManagers && records.customerManagers.length > 0) {
        let _customerManagers = records.customerManagers.filter(i => {
          return i.selected === 1
        })
        _customerManagers.length > 0
          ? (_customerManagers = _customerManagers[0].bizId)
          : (_customerManagers =
              (oldForm && oldForm.customerManagerSelectedBizId) || '')
        if (_customerManagers === '') {
          for (let i = 0; i < this.customerManagers.length; i++) {
            if (
              this.customerManagers[i].bizId ===
              window.localStorage.getItem('bizId')
            ) {
              this.form.customerManagerSelectedBizId = window.localStorage.getItem(
                'bizId'
              )
              return
            }
          }
        } else {
          this.form.customerManagerSelectedBizId = _customerManagers
        }
      }
      //   console.log('oldForm-----：' + JSON.stringify(oldForm))
      //   console.log('宴会类型-----：' + JSON.stringify(records.hall))
      if (
        records.hall &&
        records.hall.categories &&
        records.hall.categories.length > 0
      ) {
        const hallCategoriesAll = records.hall.categories.filter(i => {
          return i.selected === 1
        })
        if (hallCategoriesAll.length > 0) {
          this.initBallroomType(hallCategoriesAll[0])
          // console.log('宴会类型-----：' + hallCategoriesAll[0].bizId)
          this.form.categorySelectedBizId = hallCategoriesAll[0].bizId
          const {
            bindAttributes,
            reserveDate,
            mealStandards
          } = hallCategoriesAll[0]
          this.bindAttributes =
            bindAttributes || (oldForm && oldForm.bindAttributes)
          this.form.startDate =
            reserveDate.startDate.substr(0, 10) ||
            (oldForm && oldForm.reserveDate)
          let startTimeQuantums = reserveDate.startTimeQuantums.filter(i => {
            return i.selected === 1
          })
          startTimeQuantums.length > 0
            ? (startTimeQuantums = startTimeQuantums[0].bizId)
            : (startTimeQuantums = '')
          this.form.startTimeQuantumSelectedBizId = startTimeQuantums
          let mealStandardsbizId = mealStandards.filter(i => {
            return i.selected === 1
          })
          mealStandardsbizId.length > 0
            ? (mealStandardsbizId = mealStandards[0].bizId)
            : (mealStandardsbizId = '')
          for (let i = 0; i < this.mealStandards.length; i++) {
            if (
              oldForm &&
              oldForm.mealStandardsbizId &&
              oldForm.mealStandardsbizId === this.mealStandards[i].bizId
            ) {
              mealStandardsbizId = oldForm.mealStandardsbizId
              break
            }
          }
          this.form.mealStandardSelectedBizId = mealStandardsbizId
        } else {
          for (let i = 0; i < this.categories.length; i++) {
            if (
              oldForm &&
              oldForm.categorySelectedBizId &&
              oldForm.categorySelectedBizId === this.categories[i].bizId
            ) {
              this.form.categorySelectedBizId = oldForm.categorySelectedBizId
              break
            }
          }
          this.form.startTimeQuantumSelectedBizId =
            (oldForm && oldForm.startTimeQuantumSelectedBizId) || ''
          this.form.mealStandardSelectedBizId =
            (oldForm && oldForm.mealStandardSelectedBizId) || ''
        }
      }
      if (records.customerSources && records.customerSources.length > 0) {
        let _customerSources = records.customerSources.filter(i => {
          return i.selected === 1
        })
        _customerSources.length > 0
          ? (_customerSources = _customerSources[0].bizId)
          : (_customerSources =
              (oldForm && oldForm.customerSourceSelectedBizId) || '')
        this.form.customerSourceSelectedBizId = _customerSources
      }
      // this.form = this.extend(this.form, oldForm)
      oldForm = JSON.parse(JSON.stringify(this.form))
    },
    // 获取宴会厅客户经理
    async getCustomerManager() {
      const data = {
        pageSize: 100,
        pageNumber: 1,
        adminUser: 0,
        categories: 'banquet'
      }
      const res = await this.getRequest('ogz/user/pageable', data)
      if (res.status !== 200) {
        return false
      }
      const { records } = res.pageModel
      this.customerManagers = records.map(o => {
        return { value: o.bizId, label: o.loginUsername, bizId: o.bizId }
      })
    },
    // 宴会厅改变事件
    onBallroomChange(e) {
      this.getBallroomData(e)
    },
    // 宴会厅类型改变事件
    onBallroomTypeChange(e) {
      const typeData = records.hall.categories.find(o => {
        if (e.toString() === o.bizId) {
          return o
        }
      })
      this.initBallroomType(typeData)
      this.changeHall()
    },
    // 初始化宴会类型
    initBallroomType(typeData) {
      const { bindAttributes, reserveDate, mealStandards } = typeData
      // 显示新郎和新娘
      this.bindAttributes = ''
      if (bindAttributes) {
        this.bindAttributes = bindAttributes
      }
      if (reserveDate) {
        // 宴会开始时间
        this.startTimeQuantums = reserveDate.startTimeQuantums.map(o => {
          return {
            value: o.code,
            label: o.name,
            bizId: o.bizId
          }
        })
        if (
          reserveDate.endTimeQuantums &&
          reserveDate.endTimeQuantums.length > 0
        ) {
          // 宴会结束时间
          this.endTimeQuantums = reserveDate.endTimeQuantums.map(o => {
            return {
              value: o.code,
              label: o.name,
              bizId: o.bizId
            }
          })
        }
      }
      if (mealStandards && mealStandards.length > 0) {
        // 餐标
        this.mealStandards = mealStandards.map(o => {
          return {
            value: o.code,
            label: o.name,
            bizId: o.bizId
          }
        })
      }
    },
    onPayChange(e) {
      // console.log(this.form.paymentJson)
    },
    // 添加确认方式
    onAddconfirm() {
      const index = this.form.paymentJson.length - 1
      const paymentJson = this.form.paymentJson[index]
      switch (paymentJson.verifyMode) {
        case 1:
          if (
            !paymentJson.amount ||
            !paymentJson.payCategorySelectedBizId ||
            !paymentJson.payModeSelectedBizId
          ) {
            this.$message('请完善当前信息')
            return false
          } else {
            // 全部有值
            const confirmObject = {
              id: uuid(),
              verifyMode: null,
              payCategorySelectedBizId: '',
              payModeSelectedBizId: '',
              receiptCode: '',
              amount: ''
            }
            this.form.paymentJson.push(confirmObject)
          }
          break
        case 2:
          if (
            !paymentJson.amount ||
            !paymentJson.customerGuarantorSelectedBizId ||
            !paymentJson.guarantorCategorySelectedBizId
          ) {
            this.$message('请完善当前信息')
            return false
          } else {
            // 全部有值
            const confirmObject = {
              id: uuid(),
              verifyMode: null,
              customerGuarantorSelectedBizId: '',
              guarantorCategorySelectedBizId: '',
              amount: ''
            }
            this.form.paymentJson.push(confirmObject)
          }
          break
      }
    },
    async referList() {
      // 后台请求宴会厅接口
      this.halls = referlists.records
      this.changeHall()
    },
    changeHall() {
      this.form.referHallBizIds = []
      this.referBallRoomList = []
      var code = ''
      this.halls.forEach(r => {
        if (r.bizId === this.form.hallBizId) {
          code = r.code
        }
      })
      this.halls.forEach(item => {
        if (item.bizId === this.form.hallBizId) {
          return
        }

        item.categories.forEach(cate => {
          if (cate.bizId === this.form.categorySelectedBizId) {
            // console.log(cate.reserveDate.dateFormat)
            if (!this.form.bizId) {
              if (cate.reserveDate.dateFormat != 1) {
                // 会议类型 不能关联
                this.refeType = false
              } else {
                this.refeType = true
              }
            }
            const obj = {
              value: item.code,
              label: item.name,
              bizId: item.bizId
            }
            this.referBallRoomList.push(obj)
          }
        })
      })
    },
    async getBallrooms() {
      const records = res.pageModel.records[0]
      if (records.rooms && records.rooms.length > 0) {
        roomList = records.rooms.map(o => {
          if (o.selected === 1) {
            this.form.roomBizId = o.bizId
            this.timeQuantums = o.timeQuantums.map(o => {
              return {
                value: o.code,
                label: o.name,
                bizId: o.bizId
              }
            })
          }
          return {
            value: o.code,
            label: o.name,
            bizId: o.bizId
          }
        })
        const roomListCopy = JSON.parse(JSON.stringify(roomList))
        if (Object.keys(remainingRoom).length !== 0) {
          for (let j = roomList.length - 1; j >= 0; j--) {
            if (remainingRoom[this.form.reserveTime]) {
              for (
                let i = 0;
                i < remainingRoom[this.form.reserveTime].length;
                i++
              ) {
                if (
                  roomList[j].bizId ===
                    remainingRoom[this.form.reserveTime][i] &&
                  roomList[j].bizId !== this.form.roomBizId
                ) {
                  roomList.splice(j, 1)
                  break
                }
              }
            }
          }
        }
        this.roomList = roomList
        if (records.roomBizId) {
          this.form.roomBizId = records.roomBizId
        }
        if (Object.keys(remainingRoom).length !== 0) {
          for (let j = roomListCopy.length - 1; j >= 0; j--) {
            if (remainingRoom[this.form.reserveTime]) {
              for (
                let i = 0;
                i < remainingRoom[this.form.reserveTime].length;
                i++
              ) {
                if (
                  roomListCopy[j].bizId ===
                    remainingRoom[this.form.reserveTime][i] &&
                  roomListCopy[j].bizId !== this.form.roomBizId
                ) {
                  if (
                    this.form.referRoomBizIds &&
                    this.form.referRoomBizIds.length > 0
                  ) {
                    // const referRoomBizIds = this.form.referRoomBizIds[0].split(',')
                    if (
                      this.form.referRoomBizIds.indexOf(
                        roomListCopy[j].bizId
                      ) === -1
                    ) {
                      roomListCopy.splice(j, 1)
                      break
                    }
                  } else {
                    roomListCopy.splice(j, 1)
                    break
                  }
                }
              }
            }
          }
        }
        this.roomLists = roomListCopy.filter(o => {
          return o.bizId !== this.form.roomBizId
        })
      }
    }
  },

  mounted() {
    oldForm = null
    // console.log('oldForm', oldForm)
    // console.log('ballroom' + JSON.stringify(this.ballroomList))
    this.getBallroomData()
    this.referList()
  }
}
</script>

<style lang="scss" scoped>
.reserve-page {
  box-sizing: border-box;
  padding: 5px;
  overflow: auto;

  .form {
    box-sizing: border-box;
    height: 100%;
    width: 102%;
    overflow: auto;
    padding-right: 15px;

    .form-box {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      background: #f7f7f7;
      margin: 0;
    }

    .el-form-item {
      margin: 10px 0;
    }

    .form-box-margintop {
      margin-top: 12px;
    }

    .form-box-none {
      display: block;
      display: flex;
      //justify-content: space-between;
      align-items: center;

      .payway {
        display: flex;

        //width: 80%
        .payway-item {
          display: flex;
        }
      }

      .addpayway {
        margin-left: 20px;
        font-size: 30px;
        margin-top: -4px;
      }
    }
  }

  /deep/ .el-upload--picture-card {
    width: 100px;
    height: 100px;
  }

  /deep/ .el-upload {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
  }

  /deep/ .el-radio {
    margin-right: 5px;
  }
}
</style>
