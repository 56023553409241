<!-- 3. 宴会厅 -->
<template>
  <el-card class="type-page">
    <div class="buttongroup">
      <el-button @click="onAddClick" type="primary" icon="el-icon-plus">添加</el-button>
    </div>
    <el-table height="66vh" :data="tableData" style="width: 100%" ref="multipleTable" border>
      <el-table-column align="center" label="序号" type="index"></el-table-column>
      <!-- <el-table-column align="center" prop="createdAt" label="创建日期">
      </el-table-column> -->
      <el-table-column align="center" prop="name" label="名称"></el-table-column>
      <!-- <el-table-column align="center" prop="categoryList" label="宴会类型">
        <template slot-scope="scope">
          <el-tag style="margin: 2px" v-for="(item, index) in scope.row.categoryList" :key="index">
            {{ item }}
          </el-tag>
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="repelList" label="互斥厅">
        <template slot-scope="scope" v-if="scope.row.repelList">
          <el-tag style="margin: 2px" v-for="(item, index) in scope.row.repelList" :key="index">
            {{ item }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="maxTableNumber" label="最大桌"></el-table-column>
      <el-table-column align="center" prop="minTableNumber" label="最优桌"></el-table-column>
      <el-table-column align="center" prop="" label="状态">
        <template slot-scope="scope">
          <el-switch @change="onStatusChange(scope.row)" v-model="scope.row.status"></el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" width="150px" label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-edit" size="mini" @click="postEditData(scope.row)"></el-button>
          <el-button icon="el-icon-delete" size="mini" type="danger" @click="postDeleteData(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="search.pageSize" @current-change="onCurrentChange"></el-pagination>
    <!-- 添加 -->
    <el-dialog :close-on-click-modal="false" class="updatecode" :title="titleName + '宴会厅'" @close="closeDialog" :visible.sync="centerDialogVisible" width="1000px" center>
      <el-form label-width="100px" :model="form" :rules="rules" ref="setForm">
        <div class="el-form-box">
          <el-form-item prop="name" label="宴会厅名称">
            <el-input style="width: 260px" v-model="form.name"></el-input>
          </el-form-item>
        </div>
        <div class="el-form-box">
          <!-- <el-form-item prop="categorysArry" label="宴席类型">
            <el-select style="width: 400px" :collapse-tags="false" v-model="form.categorysArry" @change="cataeGoryChange" multiple>
              <el-option v-for="item in categoryData" :key="item.value" :label="item.label" :value="item.value">
                {{item.label}}
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="互斥宴会厅">
            <el-select style="width: 300px" :collapse-tags="false" v-model="form.exclusions" @change="ExChange" multiple>
              <el-option v-for="item in exclusionsData" :key="item.value" :label="item.label" :value="item.value">
                {{ item.label }}
              </el-option>
            </el-select>
            <el-popover
              placement="top"
              width="400"
              trigger="hover"
              content="如酒店把A宴会厅分隔为A1、A2两个宴会厅，实际可预定宴会厅为A、A1、A2，则三者在同一时间不能被同时预定则为互斥关系。A预定，则A1、A2都不能预定；A1、A2任一被预定则A不能被预定。"
            >
              <i slot="reference" class="el-icon-question" style="color: #999; margin-left: 10px; font-size: 14px"></i>
            </el-popover>
          </el-form-item>
        </div>
        <div class="el-form-box">
          <el-form-item prop="tabIndex" label="餐厅排序">
            <el-input min="1" type="number" style="width: 80px" v-model="form.tabIndex"></el-input>
          </el-form-item>
          <el-form-item prop="maxTableNumber" label="最大桌数">
            <el-input @blur="NumberBlur" min="1" type="number" style="width: 80px" v-model="form.maxTableNumber"></el-input>
          </el-form-item>
          <el-form-item label="最优桌数">
            <el-input @blur="NumberBlur" min="0" type="number" style="width: 80px" v-model="form.minTableNumber"></el-input>
          </el-form-item>
        </div>

        <!-- <div class="form-type" v-for="item in categorys" :key="item.categoryBizId">
          <div style="width: 100px">
            <el-tag>{{item.label}}</el-tag>
          </div>
          <div style="width: 800px">
            <div class="el-form-box">
              <span>餐标金额不低于</span>
              <el-input type="number" style="width: 100px; margin: 0 10px" v-model="item.minAmount" placeholder="请输入价格"></el-input>
              <span style="margin-right: 20px">元</span>
              <b style="color: #c51515">*</b>
              <el-select style="margin: 0 5px; width: 400px" v-model="item.mealStandard" collapse-tags placeholder="请选择餐标" @change="mealStandardChange" multiple>
                <el-option v-for="item1 in mealStandardData" :disabled="item.minAmount > item1.value * 1" :key="item1.bizId" :label="item1.label" :value="item1.bizId">
                  {{item1.label}}
                </el-option>
              </el-select>
            </div>
            <div class="group">
              <div style="width: 100px">
                <span>已选餐标：</span>
              </div>
              <div style="width: 500px">
                <el-tag style="margin: 5px 10px 0 0" v-for="items in item.mealStandards" :key="items.key">{{items.price}}</el-tag>
              </div>
            </div>
          </div>
        </div> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSetData">{{ titleName }}</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
let exclusionsData = []
export default {
  name: 'Ballroom',
  data() {
    return {
      titleName: '添加',
      form: {
        hotelId: '',
        code: '', //餐厅编号
        name: '', //餐厅名称
        maxTableNumber: '', //最大桌数
        minTableNumber: '', //最小桌数
        applicationName: '', //酒店标识
        tabIndex: '', //排序
        status: 1,
        categorys: '', //宴会厅类型ids
        categorysArry: [],
        repels: '', //互斥厅ids
        exclusions: [],
        categoryName: '', //宴会厅类型名称
        repelName: '', //互斥厅名称
        dataHallCategorys: [], //宴会厅类型
        dataHallRepels: [] //互斥厅
      },
      categoryData: [], // 宴会类型
      exclusionsData: [], // 宴会厅列表
      mealStandardData: [], // 宴会餐标
      tableData: [], // 表格
      centerDialogVisible: false,
      categorys: [],
      total: 0,
      search: {
        pageSize: 10,
        pageNumber: 1,
        hotelId: '',
        applicationName: ''
      },
      rules: {
        name: [{ required: true, message: '请填写宴会厅名称', trigger: 'blur' }],
        tabIndex: [{ required: true, message: '请填写排序', trigger: 'blur' }],
        maxTableNumber: [{ required: true, message: '请填写最大桌数', trigger: 'blur' }],
        categorysArry: [{ required: true, message: '请选择宴会类型', trigger: 'change' }]
      }
    }
  },
  methods: {
    // 1. 加载宴会餐标
    getQueryMealStandardData() {
      this.getRequest('data-metamodel/getList', {
        hotelId: this.search.hotelId,
        dataCode: 'mealStandard'
      }).then(res => {
        // console.log('加载宴会餐标:' + JSON.stringify(res))
        if (res.code == 200 && res.data && res.data.length > 0) {
          this.mealStandardData = res.data.map(o => {
            return {
              value: o.price,
              label: o.name,
              name: o.name,
              bizId: o.id
            }
          })
        }
      })
    },
    // 2. 查询宴会类型
    getQueryCategoryData() {
      this.getRequest('data-category/getList', {
        hotelId: this.search.hotelId
      }).then(res => {
        // console.log('宴会类型:' + JSON.stringify(res))
        if (res.code == 200 && res.data && res.data.length > 0) {
          this.categoryData = res.data.map(o => {
            return {
              id: o.id,
              value: o.id,
              label: o.name,
              bizId: o.id
            }
          })
        }
      })
    },
    // 3. 查询宴会列表互斥用
    getQuery() {
      this.getRequest('data-hall/getList', {
        hotelId: this.search.hotelId
      }).then(res => {
        // console.log('宴会列表互斥用:' + JSON.stringify(res))
        if (res.code == 200 && res.data && res.data.length > 0) {
          this.exclusionsData = res.data.map(o => {
            return {
              id: o.id,
              value: o.id,
              label: o.name,
              bizId: o.id
            }
          })
          exclusionsData = this.exclusionsData
        }
      })
    },
    // 4. 分页事件
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getQueryData()
    },
    // 5. 查询数据
    getQueryData() {
      this.getRequest('data-hall/getPage', this.search).then(res => {
        if (res.code == 200 && res.data && res.data.total > 0) {
          this.total = res.data.total
          // 处理类型
          res.data.records.forEach(element => {
            if (element.categoryName && element.categoryName != '') {
              var entitys = element.categoryName.split(',')
              element.categoryList = entitys
            }
            if (element.repelName && element.repelName != '') {
              var entitys = element.repelName.split(',')
              element.repelList = entitys
            }
            element.status = element.status == 1 ? true : false
          })
          console.log('列表查询结果：' + JSON.stringify(res.data.records))
          // 处理互斥厅
          this.tableData = res.data.records
        } else {
          this.total = 0
          this.tableData = []
        }
      })
      this.getQuery()
    },
    // 6. 打开添加对话框
    onAddClick() {
      this.getQuery()
      this.titleName = '添加'
      this.form = {
        hotelId: this.search.hotelId,
        code: this.search.code, //餐厅编号
        name: '', //餐厅名称
        maxTableNumber: '', //最大桌数
        minTableNumber: '', //最小桌数
        applicationName: this.search.applicationName, //酒店标识
        tabIndex: '', //排序
        status: 1,
        categorys: '', //宴会厅类型ids
        categorysArry: [],
        repels: '', //互斥厅ids
        exclusions: [],
        categoryName: '', //宴会厅类型名称
        repelName: '', //互斥厅名称
        dataHallCategorys: [], //宴会厅类型
        dataHallRepels: [] //互斥厅
      }
      this.categorys = []
      this.centerDialogVisible = true
      if (this.$refs.setForm) {
        this.$refs.setForm.resetFields()
      }
    },
    // 7. 打开修改对话框
    postEditData(row) {
      this.titleName = '修改'
      this.getRequest('data-hall/getDtailById/' + row.id, { id: row.id }).then(res => {
        console.log('打开修改结果：' + JSON.stringify(res))
        if (res.code == 200) {
          // 1. 餐厅
          res.data.exclusions = []
          this.form = res.data

          // 2. 处理餐厅类型
          if (this.form.dataHallCategorys && this.form.dataHallCategorys.length > 0) {
            this.categorys = this.form.dataHallCategorys.map(o => {
              return {
                categoryBizId: o.categoryId,
                id: o.categoryId,
                value: o.categoryId,
                label: o.name,
                minAmount: o.minAmount,
                maxAmount: o.maxAmount,
                hotelId: o.hotelId,
                hallId: o.hallId,
                mealStandard: o.mealStandards.map(i => {
                  return i.dataMetamodelId
                }),
                mealStandards: o.mealStandards.map(j => {
                  return {
                    id: j.dataMetamodelId,
                    bizId: j.dataMetamodelId,
                    dataMetamodelId: j.dataMetamodelId,
                    price: j.price,
                    name: j.name
                  }
                })
              }
            })

            this.form.categorysArry = this.categorys.map(o => {
              return o.categoryBizId
            })
          }

          // 3.1 设置互斥厅
          this.exclusionsData = exclusionsData.filter(o => {
            return o.bizId !== row.id
          })

          // 3.2 互斥
          if (this.form.dataHallRepels && this.form.dataHallRepels.length > 0) {
            this.form.exclusions = this.form.dataHallRepels.map(o => {
              return o.repelHallId
            })
          }
          // console.log('form：' + JSON.stringify(this.form))
          // 处理互斥厅
          this.centerDialogVisible = true
        }
      })
    },
    // 8. 关闭弹窗取消校验
    closeDialog() {
      this.$refs.setForm.resetFields()
    },
    // 9. 宴会选择类型
    cataeGoryChange(e) {
      // console.log('e', e)
      // 第1步 this.categorys包含e的留下，其他删除
      const arr = []
      this.categorys.forEach(o => {
        for (let i = 0; i < e.length; i++) {
          if (e[i] === o.categoryBizId) {
            arr.push(o)
            break
          }
        }
      })
      this.categorys = [...arr]
      const ee = [...e]
      const categorys = [...this.categorys]
      // 第2步 找出categorys 存在e的值，把e删掉
      for (let i = 0; i < ee.length; i++) {
        for (let j = 0; j < categorys.length; j++) {
          if (categorys[j].categoryBizId === ee[i]) {
            ee.splice(i, 1)
          }
        }
      }
      // 第3步 赋值
      for (let i = 0; i < ee.length; i++) {
        let data = this.categoryData.map(o => {
          if (ee[i] === o.value) {
            for (let j = 0; j < categorys.length; j++) {
              if (categorys[j].bizId === ee[i]) {
                return categorys[j]
              }
            }
            return {
              id: o.value,
              label: o.label,
              categoryBizId: o.value,
              minAmount: 0,
              mealStandard: [],
              mealStandards: []
            }
          }
        })
        data = data.filter(res => {
          return res !== undefined
        })
        this.categorys.push(...data)
      }
    },
    ExChange(e) {
      console.log('选择修改：' + e)
    },
    // 10. 最大桌最小桌
    NumberBlur(e) {
      if (this.form.minTableNumber && this.form.minTableNumber != '') {
        const max = Number(this.form.maxTableNumber)
        const min = Number(this.form.minTableNumber)
        if (max < min) {
          this.form.minTableNumber = ''
          this.$message('最大桌数应该大于最优桌数')
          return false
        }
      }
    },
    // 11. 请选择餐标
    mealStandardChange(e) {
      this.categorys.forEach(i => {
        i.mealStandards = []
        i.mealStandard.forEach(o => {
          this.mealStandardData.forEach(j => {
            if (o === j.bizId) {
              i.mealStandards.push({
                price: j.value,
                dataMetamodelId: j.bizId,
                bizId: j.bizId,
                name: j.label
              })
            }
          })
        })
      })
    },
    // 12. 提交添加和修改
    onSetData() {
      this.$refs.setForm.validate(async valid => {
        if (valid) {
          // 12.1 判断互斥不能重复
          for (let i = 0; i < this.exclusionsData.length; i++) {
            if (this.exclusionsData[i].label === this.form.name && this.form.bizId !== this.exclusionsData[i].bizId) {
              this.$message('餐厅名称不可重复,请修改!')
              return false
            }
          }
          // 12.2 判断餐标
          var categoryName = ''
          var dataHallCategorys = []
          for (let i = 0; i < this.categorys.length; i++) {
            if (this.categorys[i].mealStandard.length === 0) {
              this.$message('请设置餐标')
              return false
            }

            // 12.2.1餐费标准不能低于对话框内填写的最低金额
            const mealStandards = this.categorys[i].mealStandards
            for (let j = 0; j < mealStandards.length; j++) {
              if (mealStandards[j].price < this.categorys[i].minAmount) {
                this.$message(`宴席类型【${this.categorys[i].label}】中餐标金额不低于【${this.categorys[i].minAmount}】元必须大于等于下面所选餐标任一一个的金额`)
                return false
              }
            }
            // 分类名称
            categoryName = categoryName + this.categorys[i].label + ','
            // 分类
            var DataHallCategory = {
              categoryId: this.categorys[i].id,
              name: this.categorys[i].label,
              minAmount: this.categorys[i].minAmount ? this.categorys[i].minAmount : 0,
              maxAmount: this.categorys[i].maxAmount ? this.categorys[i].maxAmount : 0,
              mealStandards: mealStandards
            }
            dataHallCategorys.push(DataHallCategory)
          }
          // 12.3 处理分类
          this.form.categorys = this.form.categorysArry.join(',')
          this.form.dataHallCategorys = dataHallCategorys
          if (categoryName != '') {
            categoryName = categoryName.substring(0, categoryName.length - 1)
          }
          this.form.categoryName = categoryName //宴会厅类型名称

          // 12.4 处理互斥

          console.log('处理互斥:' + this.form.exclusions)
          if (this.form.exclusions && this.form.exclusions.length > 0) {
            // 12.4.1 互斥厅id
            this.form.repels = this.form.exclusions.join(',')
            // 12.4.2 循环互斥厅,取得互斥厅名称
            var repelName_s = ''
            var dataHallRepels = []
            this.form.exclusions.forEach(element => {
              var repelName = ''
              for (var i = 0; i < this.exclusionsData.length; i++) {
                if (this.exclusionsData[i].id == element) {
                  repelName = this.exclusionsData[i].label
                  dataHallRepels.push({
                    repelHallId: this.exclusionsData[i].id,
                    repelHallName: this.exclusionsData[i].label
                  })
                  break
                }
              }
              if (repelName != '') {
                repelName_s = repelName_s + repelName + ','
              }
            })
            if (repelName_s != '') {
              repelName_s = repelName_s.substring(0, repelName_s.length - 1)
            }
            this.form.repelName = repelName_s //互斥厅名称
            this.form.dataHallRepels = dataHallRepels //互斥厅
          } else {
            this.form.repels = ''
            this.form.repelName = ''
            this.form.dataHallRepels = []
          }

          this.form.hotelId = this.org.id

          console.log('提交餐厅:' + JSON.stringify(this.form))
          if (this.titleName === '添加') {
            this.postAdd()
          } else {
            this.postEdit()
          }
        }
      })
    },
    postAdd() {
      this.post('data-hall/add', this.form).then(res => {
        if (res.code == 200) {
          this.$message({
            duration: 10000,
            message: '添加成功',
            type: 'success'
          })
          this.getQueryData()
          this.centerDialogVisible = false
        } else {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    postEdit() {
      this.post('data-hall/edit', this.form).then(res => {
        if (res.code == 200) {
          this.$message({
            duration: 10000,
            message: '添加成功',
            type: 'success'
          })
          this.getQueryData()
          this.centerDialogVisible = false
        } else {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    // 修改状态
    onStatusChange(row) {
      this.post('data-hall/statusChange', {
        id: row.id,
        status: row.status ? 1 : 0
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            duration: 10000,
            message: '状态成功',
            type: 'success'
          })
          this.getQueryData()
        } else {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    // 删除数据
    postDeleteData(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.getRequest('data-hall/delByIds/' + row.id, { ids: row.id }).then(res => {
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: '删除成功!'
              })
              this.getQueryData()
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  },
  mounted() {
    // 1. 加载酒店id
    var orgs = window.localStorage.getItem('orgs')
    this.org = JSON.parse(orgs)[0]
    this.search.hotelId = this.org.id
    this.search.applicationName = this.org.applicationName
    // 2. 加载宴会餐标
    this.getQueryMealStandardData()
    // 3. 加载宴会餐标
    this.getQueryCategoryData()
    // 4. 加载翻页查询
    this.getQueryData()
  }
}
</script>

<style lang="scss" scoped>
.type-page {
  width: 100%;
  height: 100%;
  position: relative;
  .buttongroup {
    margin-bottom: 10px;
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .form-item-box {
    display: flex;
    justify-content: space-between;
  }
  .updatecode {
    .el-form-box {
      display: flex;
      align-items: center;
    }
    .form-type {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      flex-wrap: wrap;
      background: #fcfcfc;
      padding: 5px;
      .el-form-item {
        margin: 0;
        margin-left: 20px;
        margin-right: 20px;
      }
      .group {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        margin: 10px 0;
      }
    }
  }
}
</style>
